/* eslint-disable import/no-extraneous-dependencies */
import Axios, { AxiosInstance, AxiosResponse } from 'axios';
import { InjectedStore } from 'redux-core/types';
import { setAppMessage } from 'redux-core/app/messages';

import { IS_TEST_ENV } from 'utils/constants';

import KoddiThemeAPI from './Theme';
import KoddiAuthAPI from './Auth';

import { API_CACHE_LOCAL_STORAGE_KEY } from './cachedAPI';
import { KoddiAPIRecord, KoddiAdminAPIS } from './api.types';
import { UNAUTHORIZED_REQUEST_MESSAGE } from './api.messages';
import { createKoddiApis } from './api.utils';
import KoddiAdvertiserAPI from './Advertiser';
import KoddiCampaignsAPI from './Campaigns';
import KoddiAdGroupsAPI from './AdGroups';
import SessionAPI from './Session';
import ReportAPI from './Reports';
import KoddiEntitiesAPI from './Entities';
import KoddiBillingAPI from './Billing';
import KoddiRecommendationsAPI from './KoddiRecommendations';
import { EMBEDDED_ERROR_PATH } from '../modules/constants/routes';
import { KoddiAuthContextValues } from '../contexts/AuthContext/Auth.type';
import { sendEmbeddedErrorMessage } from '../features/EmbeddedApp/EmbeddedApp.utils';

const DEFAULT_ERROR_MESSAGE = 'There was an processing your request.';
const NO_PERMISSION = 'You do not have permission to view this information.';

export const msInOneMinute = 1000 * 60;
export const TWO_MINUTES = msInOneMinute * 2;
export const TWENTY_MINUTES = msInOneMinute * 20;

export const getReturnRoute = (): string => {
    const hasReturnRoute = window.location.hash.includes('returnRoute');
    const returnRoute = hasReturnRoute
        ? window.location.hash.split('?returnRoute=')?.[1]?.replace('#', '')
        : window.location.hash.replace('#', '');
    return returnRoute;
};

export const LAST_ACTIVITY_TIME_KEY = 'last-activity';

export function createAPIError(
    defaultMessage: string,
    errorResponse?: Record<string, any>
): any {
    return {
        error: new Error(defaultMessage),
        code: errorResponse?.data?.code,
        errorResponse,
    };
}

const getEnvironment = (targetEnv: string, deployEnv: string) => {
    if (targetEnv && deployEnv) {
        return `${targetEnv}-${deployEnv}`;
    }
    return targetEnv;
};

/**
 * The `KoddiAPI` is a centralized api HUB for Koddi One.
 *
 * The `KoddiAPI` contains all of the available api methods and actions. It gracefully
 * handles errors and authorization issues and manages authentication tokens as well.
 */
export class KoddiAPIClass {
    protected axios: AxiosInstance | null = null;

    protected store!: InjectedStore;

    protected authContext!: KoddiAuthContextValues;

    private apis: KoddiAPIRecord | null = IS_TEST_ENV
        ? this.createKoddiApis(Axios)
        : null;

    private AuthAPI = new KoddiAuthAPI();

    private token: string | null = null;

    private tokenExpirationTime: number | null = null;

    private refreshTimer: number | null = null;

    public createPublicAPI = (): void => {
        this.axios = Axios.create({
            baseURL: window.API_ROUTE,
            headers: {
                'Content-Type': 'application/json',
                'application-name': 'koddi-one-ui',
                environment: getEnvironment(
                    window.TARGET_ENV,
                    window.DEPLOY_ENV
                ),
            },
        });
        this.createKoddiApis(this.axios);
    };

    public setLastActivityTime = (time: number | null): void => {
        try {
            if (time) {
                localStorage.setItem(LAST_ACTIVITY_TIME_KEY, time?.toString());
            } else {
                localStorage.removeItem(LAST_ACTIVITY_TIME_KEY);
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.warn('could not set last activity time', e);
        }
    };

    public getLastActivityTime = (): number | null => {
        try {
            const localStorageActivity = localStorage.getItem(
                LAST_ACTIVITY_TIME_KEY
            );
            return Number(localStorageActivity) || null;
        } catch (e) {
            // eslint-disable-next-line no-console
            console.warn('could not get last activity time', e);
            return null;
        }
    };

    public getAxiosInstance = (): AxiosInstance | null => {
        return this.axios;
    };

    /**
     * When a user has been signed out we disable access
     * to the authenticated apis.
     */
    public signOut = (): void => {
        this.apis = null;
        this.createPublicAPI();
        this.clearRefreshTokenTimer();
        localStorage.removeItem(API_CACHE_LOCAL_STORAGE_KEY);

        // We need apis to be available during testing
        if (IS_TEST_ENV) this.createKoddiApis(Axios);
    };

    /**
     * When a user has been validated we create the axios instance to
     * enable the authenticated apis.
     */
    public signIn = (session: string, expirationTime: number): void => {
        const axios = this.createAxiosInstance(session, expirationTime);
        this.createKoddiApis(axios);
    };

    /**
     * Creates the global axios instance that will be used by all Koddi
     * API's that contains the appropriate authorization headers for every
     * request.
     */
    private createAxiosInstance(
        token: string,
        expirationTime: number
    ): AxiosInstance {
        this.axios = Axios.create({
            baseURL: window.API_ROUTE,
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
                'application-name': 'koddi-one-ui',
                environment: getEnvironment(
                    window.TARGET_ENV,
                    window.DEPLOY_ENV
                ),
            },
        });
        this.token = token;
        this.tokenExpirationTime = expirationTime;
        this.startRefreshTokenTimer();

        this.axios.interceptors.response.use(
            this.handleSuccessfulResponse,
            this.handleErroredResponse
        );

        return this.axios;
    }

    public isUserIdle = (): boolean => {
        const idleThreshhold = +new Date() - TWENTY_MINUTES;
        const lastActivity = this.getLastActivityTime();
        return !!(lastActivity && lastActivity < idleThreshhold);
    };

    /**
     * Starts a timer to refresh the users token 5 minutes
     * before it expires.
     */

    private startRefreshTokenTimer = () => {
        if (this.tokenExpirationTime) {
            const expiresInMilliseconds =
                this.tokenExpirationTime - +new Date();
            // eslint-disable-next-line no-unused-vars
            const msUntilRefreshToken = expiresInMilliseconds - TWO_MINUTES;
            if (this.refreshTimer) return;
            this.refreshTimer = setTimeout(() => {
                this.refreshTimer = null;
                if (!this.isUserIdle()) {
                    return this.authContext?.handleSilentRefreshToken();
                }
                return this.authContext?.handlePromptRefreshToken();
            }, msUntilRefreshToken);
        }
    };

    /**
     * Cancels the pending refresh token timer.
     */
    private clearRefreshTokenTimer = () => {
        if (this.refreshTimer !== null) {
            clearTimeout(this.refreshTimer);
        }
    };

    private handleSuccessfulResponse = (response: AxiosResponse) => {
        this.setLastActivityTime(+new Date());
        return response;
    };

    /**
     * The Koddi API Global Axios instance response interceptor for errored responses.
     * Handles unauthorized api requests as well as localizing error responses.
     */
    private handleErroredResponse = (error: any) => {
        this.setLastActivityTime(+new Date());
        // client received an error response (5xx, 4xx)
        if (error.response) {
            // send postMessage to embedded Iframes
            sendEmbeddedErrorMessage(
                `${error.response.status}: ${error.response.data?.error}`
            );
            if (error.response.status === 401) {
                return this.dispatchLogout(UNAUTHORIZED_REQUEST_MESSAGE);
            }
            if (error.response.status === 403) {
                return Promise.reject(
                    createAPIError(NO_PERMISSION, error.response)
                );
            }
            if (error?.response?.data?.error) {
                return Promise.reject(
                    createAPIError(error.response.data.error, error.response)
                );
            }
            return Promise.reject(
                createAPIError(DEFAULT_ERROR_MESSAGE, error.response)
            );
        }

        // ad block detected
        if (
            error.message === 'Network Error' &&
            error.config?.url.includes('advertiser')
        ) {
            if (this.store) {
                this.store.dispatch(
                    setAppMessage(
                        'error',
                        'Ad Blocker Detected',
                        'Please disable your ad blocker to view all details on this page',
                        error.config.url
                    )
                );
            }
        }

        // client never received a response, or request never left
        if (error.request) {
            return Promise.reject(
                createAPIError(DEFAULT_ERROR_MESSAGE, error.response)
            );
        }
        // anything else
        return Promise.reject(error);
    };

    private createKoddiApis(axios: AxiosInstance) {
        const apis = createKoddiApis(axios);
        this.apis = apis;
        return apis;
    }

    private dispatchLogout(message: string) {
        // if we hit a login token error from embedded, redirect to error page
        const { href } = window.location;
        if (href.includes('embedded')) {
            window.location.href = `#${EMBEDDED_ERROR_PATH}`;
            return; // dont dispatch logout instead redirect to error page
        }
        this.authContext?.handleLogout(message);
    }

    /** Below are all of the publically available apis. */
    /**
     * Sets the redux store on the Koddi API.
     */
    public connectAuthContext(context: KoddiAuthContextValues): void {
        this.authContext = context;
    }

    private getAPI(feature: keyof KoddiAPIRecord) {
        if (this.apis && this.apis[feature]) return this.apis[feature];
        this.dispatchLogout(UNAUTHORIZED_REQUEST_MESSAGE);
        throw new Error(UNAUTHORIZED_REQUEST_MESSAGE);
    }

    /**
     * The Koddi Auth API
     */
    get Auth(): KoddiAuthAPI {
        return this.AuthAPI;
    }

    /**
     * API's related to ad groups accross multiple advertisers.
     */
    get AdGroups(): KoddiAdGroupsAPI {
        return this.getAPI('AdGroups') as KoddiAdGroupsAPI;
    }

    /**
     * API's related to admin functionality.
     */
    get Admin(): KoddiAdminAPIS {
        return this.getAPI('Admin') as KoddiAdminAPIS;
    }

    /**
     * API's related to a specific advertiser.
     */
    get Advertiser(): KoddiAdvertiserAPI {
        return this.getAPI('Advertiser') as KoddiAdvertiserAPI;
    }

    /**
     * API's related to entities.
     */
    get Entities(): KoddiEntitiesAPI {
        return this.getAPI('Entities') as KoddiEntitiesAPI;
    }

    /**
     * API's related to campaigns accross multiple advertisers.
     */
    get Campaigns(): KoddiCampaignsAPI {
        return this.getAPI('Campaigns') as KoddiCampaignsAPI;
    }

    /**
     * API's related to a users session.
     */
    get Session(): SessionAPI {
        return this.getAPI('Session') as SessionAPI;
    }

    /**
     * API's related to themes for a specific member group.
     */
    get Theme(): KoddiThemeAPI {
        return this.getAPI('Theme') as KoddiThemeAPI;
    }

    /**
     * API's related to report generation
     */
    get Report(): ReportAPI {
        return this.getAPI('Reports') as ReportAPI;
    }

    /**
     * API's related to a billing/stripe.
     */
    get Billing(): KoddiBillingAPI {
        return this.getAPI('Billing') as KoddiBillingAPI;
    }

    /**
     * API's related to Koddi Bid/Budget Recommendations.
     */
    get KoddiRecommendations(): KoddiRecommendationsAPI {
        return this.getAPI('KoddiRecommendations') as KoddiRecommendationsAPI;
    }
}
