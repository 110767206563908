/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// Documentation for Auth0's JS SDK https://auth0.com/docs/libraries/auth0-single-page-app-sdk
import { Auth0Client, createAuth0Client } from '@auth0/auth0-spa-js';
import { Auth0State } from './Auth0.types';
import { AUTH0_CONFIG, AUTH_LOGOUT_KEY } from './Auth0.const';

export class KoddiAuth0 {
    auth0Client: Auth0Client | undefined;

    isAuthenticated = false;

    public getState = async (): Promise<Auth0State> => {
        if (!this.auth0Client) {
            await this.configureClient();
        }
        this.isAuthenticated =
            (await this.auth0Client?.isAuthenticated()) ?? false;
        if (!this.isAuthenticated || !this.auth0Client) return null;

        const tokens = await this.auth0Client.getTokenSilently({
            authorizationParams: {
                audience: AUTH0_CONFIG?.authorizationParams?.audience,
            },
            cacheMode: 'off',
            detailedResponse: true,
        });

        const { id_token, access_token } = tokens;

        // get details after new tokens are retrieved
        const user = await this.auth0Client.getUser();

        const claims = await this.auth0Client.getIdTokenClaims();

        // values in seconds
        const idTokenExpirationTime = claims?.exp ? claims.exp : 0;
        const idTokenIssuedAtTime = claims?.iat ? claims.iat : 0;
        return {
            isAuthenticated: this.isAuthenticated,
            user,
            idToken: id_token,
            accessToken: access_token,
            expires_in: idTokenExpirationTime - idTokenIssuedAtTime,
            expirationTime: idTokenExpirationTime * 1000,
        };
    };

    private handleCallBack = async () => {
        const { search, hash } = window.location;
        if (search.includes('code=') && search.includes('state=')) {
            try {
                await this.auth0Client?.handleRedirectCallback();
                window.history.replaceState({}, document.title, hash);
            } catch (error) {
                console.error(error);
                return null;
            }
        }

        return this.getState();
    };

    private configureClient = async () => {
        if (this.auth0Client) return;
        this.auth0Client = await createAuth0Client(AUTH0_CONFIG);
    };

    /**
     * Starts the sign up flow
     */
    public signUp = async (targetUrl?: string) => {
        if (!this.auth0Client) {
            throw Error('The auth0 client is not defined');
        }
        const options = {
            authorizationParams: {
                redirect_uri: targetUrl ?? `${window.location.origin}`,
                screen_hint: 'signup',
            },
            appState: targetUrl ?? null,
        };
        await this.auth0Client.loginWithRedirect(options);
    };

    /**
     * Starts the authentication flow
     */
    public login = async ({
        sso_organization_id,
        targetUrl,
        sso_connection_id,
    }: {
        sso_organization_id?: string;
        targetUrl?: string;
        sso_connection_id?: string;
    }) => {
        if (!this.auth0Client) {
            throw Error('The auth0 client is not defined');
        }
        const options = {
            authorizationParams: {
                redirect_uri: targetUrl ?? `${window.location.origin}`,
                organization: sso_organization_id ?? undefined,
                connection_id: sso_connection_id ?? undefined,
            },
            appState: targetUrl ?? null,
        };
        await this.auth0Client?.loginWithRedirect(options);
    };

    /**
     * Executes the logout flow
     */
    public logout = async (returnTo = '') => {
        if (!this.isAuthenticated) return;
        if (!this.auth0Client) {
            throw Error('The auth0 client is not defined');
        }
        await this.auth0Client.logout({
            logoutParams: {
                returnTo: `${window.location.origin}/#${returnTo}`,
            },
        });
        localStorage.setItem(AUTH_LOGOUT_KEY, 'true');
    };

    public async initializeAuth0() {
        await this.configureClient();
        return this.handleCallBack();
    }
}

export const Auth0 = new KoddiAuth0();
