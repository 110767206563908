/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { AxiosInstance } from 'axios';

class SessionAPI {
    protected axios: AxiosInstance;

    constructor(axios: AxiosInstance) {
        this.axios = axios;
    }

    /**
     * Removes the user’s sessions.
     */
    public logout = async (reason?: string): Promise<string | undefined> => {
        await this.axios.post('v1/session/logout');
        this.axios.defaults.withCredentials = false;
        return reason;
    };

    /**
     * Refreshes the user session
     */
    public refresh = async (
        refreshToken: string
    ): Promise<string | undefined> => {
        const response = await this.axios.patch('v1/session/refresh', {
            refresh_token: refreshToken,
        });
        return response.data.result.token;
    };
}

export default SessionAPI;
