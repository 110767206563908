import { useCToast } from 'app/koddi-components/src/_ChakraComponents';
import KoddiAPI from 'app/react-ui/api/api.instance';
import { useOnFirstRender } from 'app/react-ui/hooks/useOnFirstRender.hooks';
import { Auth0, Auth0State } from 'app/react-ui/modules/Auth0';
import {
    AUTH_LOGOUT_KEY,
    RETURN_PARAMS_SESSION_KEY,
    RETURN_ROUTE_SESSION_KEY,
    USER_ATTEMPTED_LOGIN_KEY,
} from 'app/react-ui/modules/Auth0/Auth0.const';
import {
    useKoddiThemeResource,
    useSSOIds,
} from 'app/react-ui/redux-core/app/theme/hooks';
import { updateUser } from 'app/react-ui/redux-core/auth/actions';
import formatDateForKoddiAPI from 'app/react-ui/utils/formatDateForKoddiAPI';
import { formatAPIError } from 'app/react-ui/utils/formatError';
import React, {
    FunctionComponent,
    createContext,
    useMemo,
    useEffect,
    useState,
    useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { KoddiAuthContextValues, NewUserSignInArgs } from './Auth.type';
import {
    callThenPause,
    getClientNameFromPath,
    getExpirationTime,
    handleReturnRouteStorage,
    hasExpirationTimePast,
    returnToLoginRoute,
} from './Auth.utils';
import { useRefreshTokenPromptModal } from './modals/RefreshTokenPrompt.component';

export const KoddiAuthContext = createContext<
    KoddiAuthContextValues | undefined
>(undefined);

/**
 *
 * @param hasExternalToken is used for wrapping embedded app components. this is to ensure that the internal components work in bother areas of the app, but it is expected for the parent to handle the token and API Sign in
 */
export const KoddiAuthProvider: FunctionComponent<{
    hasExternalToken?: boolean;
    // eslint-disable-next-line react/display-name
}> = React.memo(({ hasExternalToken, children }) => {
    const location = useLocation();
    const history = useHistory();
    const { showErrorToast, showWarningToast } = useCToast();
    const { member_group_id, theme } = useKoddiThemeResource();
    const [isLoadingAuth0, setIsLoadingAuth0] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
        !!hasExternalToken
    );
    const [authState, setAuthState] = useState<Auth0State>(null);
    const [isTemporaryToken, setIsTemporaryToken] = useState<boolean>(false);
    const { sso_organization_id, sso_connection_id } = useSSOIds();
    const dispatch = useDispatch();

    const handleSignUp = useCallback(async () => {
        try {
            await Auth0.signUp();
        } catch (err) {
            showErrorToast('Error', formatDateForKoddiAPI(err));
        }
    }, [showErrorToast]);

    const pushToAuth0Login = useCallback(async () => {
        try {
            if (hasExternalToken) return;
            sessionStorage.setItem(USER_ATTEMPTED_LOGIN_KEY, 'true');
            if (!sso_organization_id) {
                const hostName = getClientNameFromPath(location.pathname);
                const hostNameTheme = await KoddiAPI.Admin.MemberGroup.getThemeByHostname(
                    hostName
                );
                await Auth0.login({
                    sso_organization_id: hostNameTheme?.sso_organization_id,
                    sso_connection_id: hostNameTheme?.sso_connection_id,
                    targetUrl: `${window.location.origin}/#/clients/${hostNameTheme?.member_group_id}`,
                });
            }
            await Auth0.login({
                sso_organization_id,
                sso_connection_id,
                targetUrl: `${window.location.origin}/#/clients/${member_group_id}`,
            });
        } catch (err) {
            showErrorToast(formatAPIError(err));
        }
    }, [
        hasExternalToken,
        sso_organization_id,
        sso_connection_id,
        member_group_id,
        location.pathname,
        showErrorToast,
    ]);

    const handleLogout = useCallback(
        async (message?: string) => {
            try {
                if (hasExternalToken) return;
                if (message) {
                    const duration = 1000;
                    await callThenPause(
                        () =>
                            showWarningToast('Signing Out', message, {
                                duration,
                                id: 'logout-warning',
                            }),
                        duration + 500
                    );
                }
                // logout of API
                await KoddiAPI.signOut();
                if (isTemporaryToken) {
                    // logout Cognito
                    await KoddiAPI.Session.logout();
                    history.push(
                        returnToLoginRoute({ loginKey: theme?.login?.key })
                    );
                    return;
                }
                // logout Auth0
                await Auth0.logout(
                    returnToLoginRoute({ loginKey: theme?.login?.key })
                );
            } catch (err) {
                showErrorToast('Failed to logout', formatAPIError(err));
            }
        },
        [
            hasExternalToken,
            history,
            isTemporaryToken,
            showErrorToast,
            showWarningToast,
            theme.login?.key,
        ]
    );

    /**
     * use to create a temporary Authentication for a new user
     */
    const handleSignInWithNewUser = useCallback(
        async ({ token, email }: NewUserSignInArgs) => {
            try {
                await KoddiAPI.signIn(
                    token.id_token,
                    getExpirationTime(token.expires_in)
                );
                const koddiUser = await KoddiAPI.Admin.User.get(email);

                dispatch(updateUser(koddiUser));
                setIsTemporaryToken(true);
                setIsAuthenticated(true);
                setIsLoadingAuth0(false);
            } catch (err) {
                showErrorToast('Sign In Error', formatAPIError(err));
                handleLogout();
                pushToAuth0Login();
            }
        },
        [dispatch, handleLogout, pushToAuth0Login, showErrorToast]
    );

    const handleAPIAuthentication = useCallback(
        async (auth0State: Auth0State) => {
            try {
                setAuthState(auth0State);
                if (!auth0State) throw Error('not authenticated');
                const {
                    idToken,
                    accessToken,
                    expirationTime,
                    user,
                } = auth0State;

                await KoddiAPI.signIn(idToken, expirationTime);
                await KoddiAPI.Auth.postAuth0Token(
                    member_group_id,
                    idToken,
                    accessToken
                );
                if (!user?.email) {
                    throw Error('Your user was not found');
                }
                const koddiUser = await KoddiAPI.Admin.User.get(user.email);
                dispatch(updateUser(koddiUser));
            } catch (err) {
                await callThenPause(
                    () => showErrorToast('Login Error', formatAPIError(err)),
                    1000
                );
                handleLogout();
            }
        },
        [dispatch, handleLogout, member_group_id, showErrorToast]
    );

    // refresh token
    const {
        openRefreshTokenPrompt,
        RefreshTokenPrompt,
    } = useRefreshTokenPromptModal();

    const handleSilentRefreshToken = useCallback(async () => {
        try {
            if (hasExternalToken) return;
            const auth0State = await Auth0.getState();
            if (auth0State) {
                handleAPIAuthentication(auth0State);
            } else {
                throw Error('We could not refresh this session');
            }
        } catch (error) {
            showErrorToast('Refresh Token Error', formatAPIError(error));
            handleLogout();
        }
    }, [
        hasExternalToken,
        handleAPIAuthentication,
        handleLogout,
        showErrorToast,
    ]);

    const handlePromptRefreshToken = useCallback(() => {
        if (hasExternalToken) return;
        openRefreshTokenPrompt();
    }, [hasExternalToken, openRefreshTokenPrompt]);

    const handleSilentAuthentication = useCallback(async () => {
        try {
            if (hasExternalToken) return;

            const auth0state = await Auth0.initializeAuth0();
            // prevent using an expired token stored in Storage
            if (
                auth0state &&
                hasExpirationTimePast(auth0state?.expirationTime)
            ) {
                throw Error('Session has expired');
            }
            if (auth0state) {
                setIsAuthenticated(true);
                const returnRouteFromStorage = sessionStorage.getItem(
                    RETURN_ROUTE_SESSION_KEY
                );
                const returnParams = sessionStorage.getItem(
                    RETURN_PARAMS_SESSION_KEY
                );
                if (returnRouteFromStorage) {
                    history.push(returnRouteFromStorage + returnParams);
                }
                await handleAPIAuthentication(auth0state);
                sessionStorage.removeItem(RETURN_ROUTE_SESSION_KEY);
                localStorage.setItem(AUTH_LOGOUT_KEY, 'false');
                return;
            }
            // get sso ID
            const hostName = getClientNameFromPath(location.pathname);
            handleReturnRouteStorage({
                route: location.pathname,
                searchParams: location.search,
                forceOverwrite: true,
            });
            setIsAuthenticated(false);
            history.push(
                returnToLoginRoute({
                    loginKey: hostName ?? theme.login?.key,
                })
            );
        } catch (e) {
            showErrorToast('Authentication Failed', formatAPIError(e));
            handleLogout();
        } finally {
            setIsLoadingAuth0(false);
        }
    }, [
        hasExternalToken,
        location.pathname,
        location.search,
        history,
        theme.login?.key,
        handleLogout,
        handleAPIAuthentication,
        showErrorToast,
    ]);

    // initialize and check if user is logged in or not
    useOnFirstRender(() => {
        handleSilentAuthentication();
    });

    // watch for return route
    const returnRoute = new URLSearchParams(location.search).get('returnRoute');
    useEffect(() => {
        if (returnRoute) {
            handleReturnRouteStorage({ route: returnRoute });
        }
    }, [returnRoute]);

    // watch for other tab logouts
    useEffect(() => {
        function handleStorageChange(e: StorageEvent) {
            if (e.key === AUTH_LOGOUT_KEY && e.newValue === 'true') {
                handleLogout();
            }
        }
        window.addEventListener('storage', handleStorageChange);
        return function cleanup() {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [handleLogout]);

    const contextValues: KoddiAuthContextValues = useMemo(() => {
        return {
            authState,
            isLoadingAuth0,
            isAuthenticated,
            pushToAuth0Login,
            handleLogout,
            handleSignUp,
            handleSignInWithNewUser,
            handleSilentRefreshToken,
            handlePromptRefreshToken,
        };
    }, [
        authState,
        isLoadingAuth0,
        isAuthenticated,
        pushToAuth0Login,
        handleLogout,
        handleSignUp,
        handleSignInWithNewUser,
        handleSilentRefreshToken,
        handlePromptRefreshToken,
    ]);

    useEffect(() => {
        if (!contextValues || hasExternalToken) return;
        KoddiAPI.connectAuthContext(contextValues);
    }, [contextValues, hasExternalToken]);

    return (
        <KoddiAuthContext.Provider value={contextValues}>
            {!hasExternalToken && (
                <div data-test="refresh-token-prompt">
                    <RefreshTokenPrompt
                        onSubmit={handleSilentAuthentication}
                        onExpire={handleLogout}
                    />
                </div>
            )}
            {children}
        </KoddiAuthContext.Provider>
    );
});
